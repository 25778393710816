/**
 * HAM API – Generated by orval 🍺
 * Do not edit manually.
 */
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { ErrorType } from '../../axios';
import { request } from '../../axios';
import type { StatisticsDto } from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getStatistics = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<StatisticsDto>({ url: `/intranet-api/statistics`, method: 'GET', signal }, options);
};

export const getGetStatisticsQueryKey = () => {
  return [`/intranet-api/statistics`] as const;
};

export const getGetStatisticsQueryOptions = <
  TData = Awaited<ReturnType<typeof getStatistics>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getStatistics>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStatisticsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStatistics>>> = ({ signal }) =>
    getStatistics(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getStatistics>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetStatisticsQueryResult = NonNullable<Awaited<ReturnType<typeof getStatistics>>>;
export type GetStatisticsQueryError = ErrorType<unknown>;

export function useGetStatistics<
  TData = Awaited<ReturnType<typeof getStatistics>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getStatistics>>, TError, TData>> &
    Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getStatistics>>, TError, TData>, 'initialData'>;
  request?: SecondParameter<typeof request>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetStatistics<
  TData = Awaited<ReturnType<typeof getStatistics>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getStatistics>>, TError, TData>> &
    Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getStatistics>>, TError, TData>, 'initialData'>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetStatistics<
  TData = Awaited<ReturnType<typeof getStatistics>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getStatistics>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetStatistics<
  TData = Awaited<ReturnType<typeof getStatistics>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getStatistics>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetStatisticsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}
