import { CheckCircleOutline, CircleOutlined, HelpOutline, RemoveCircleOutline } from '@mui/icons-material';
import { ColorPaletteProp, Divider, Option, Select, Typography } from '@mui/joy';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { RegistrationDto, RegistrationDtoVerificationState } from '../api/generated/model';
import { getDecisionText } from '../utils/decision.ts';

export function DecisionSelect({ registration }: { registration: RegistrationDto }) {
  const navigate = useNavigate();

  return (
    <Select<RegistrationDtoVerificationState>
      size="sm"
      value={
        registration.verificationState !== RegistrationDtoVerificationState.INITIAL
          ? registration.verificationState
          : null
      }
      onChange={(_, value: string | null) => {
        if (value) {
          void navigate(`/registration/${registration.id}/decision/${value}`);
        }
      }}
      placeholder={<Decision status={RegistrationDtoVerificationState.INITIAL} colored />}
      renderValue={(selected) => selected && <Decision status={selected.value} colored />}>
      <DecisionOption value={RegistrationDtoVerificationState.MODEL_CORRECT_DOCTOR_CORRECT} />
      <DecisionOption value={RegistrationDtoVerificationState.MODEL_CORRECT_DOCTOR_CHANGE_NEEDED} />
      <DecisionOption value={RegistrationDtoVerificationState.MODEL_CORRECT_DOCTOR_CORRECT_IN_FUTURE} />
      <Divider />
      <DecisionOption value={RegistrationDtoVerificationState.MODEL_WRONG} />
      <Divider />
      <DecisionOption value={RegistrationDtoVerificationState.UNCLEAR} />
    </Select>
  );
}

function DecisionOption({ value }: { value: RegistrationDtoVerificationState }) {
  return (
    <Option value={value}>
      <Decision status={value} />
    </Option>
  );
}

function Decision({ status, colored = false }: { status: RegistrationDtoVerificationState; colored?: boolean }) {
  const { color, icon } = statusMap[status];
  const text = getDecisionText(status);
  return (
    <Typography fontSize={14} color={colored ? color : 'neutral'} startDecorator={icon}>
      {text}
    </Typography>
  );
}

const statusMap = {
  [RegistrationDtoVerificationState.INITIAL]: {
    color: 'neutral',
    icon: <CircleOutlined />,
  },
  [RegistrationDtoVerificationState.UNCLEAR]: {
    color: 'warning',
    icon: <HelpOutline />,
  },
  [RegistrationDtoVerificationState.MODEL_WRONG]: {
    color: 'success',
    icon: <RemoveCircleOutline />,
  },
  [RegistrationDtoVerificationState.MODEL_CORRECT_DOCTOR_CHANGE_NEEDED]: {
    color: 'success',
    icon: <CheckCircleOutline />,
  },
  [RegistrationDtoVerificationState.MODEL_CORRECT_DOCTOR_CORRECT_IN_FUTURE]: {
    color: 'success',
    icon: <CheckCircleOutline />,
  },
  [RegistrationDtoVerificationState.MODEL_CORRECT_DOCTOR_CORRECT]: {
    color: 'success',
    icon: <CheckCircleOutline />,
  },
} as { [key in RegistrationDtoVerificationState]: { color: ColorPaletteProp; icon: ReactNode } };
