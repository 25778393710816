/**
 * HAM API – Generated by orval 🍺
 * Do not edit manually.
 */
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { BodyType, ErrorType } from '../../axios';
import { request } from '../../axios';
import type {
  EmailTemplateDto,
  GetEmailTemplateForStateParams,
  Section,
  UpdateTemplateImageBody,
  UpdateTextDto,
} from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const updateTemplateText = (
  sectionId: string,
  textId: string,
  updateTextDto: BodyType<UpdateTextDto>,
  options?: SecondParameter<typeof request>,
) => {
  return request<void>(
    {
      url: `/intranet-api/templates/section/${sectionId}/text/${textId}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateTextDto,
    },
    options,
  );
};

export const getUpdateTemplateTextMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTemplateText>>,
    TError,
    { sectionId: string; textId: string; data: BodyType<UpdateTextDto> },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTemplateText>>,
  TError,
  { sectionId: string; textId: string; data: BodyType<UpdateTextDto> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTemplateText>>,
    { sectionId: string; textId: string; data: BodyType<UpdateTextDto> }
  > = (props) => {
    const { sectionId, textId, data } = props ?? {};

    return updateTemplateText(sectionId, textId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateTemplateTextMutationResult = NonNullable<Awaited<ReturnType<typeof updateTemplateText>>>;
export type UpdateTemplateTextMutationBody = BodyType<UpdateTextDto>;
export type UpdateTemplateTextMutationError = ErrorType<unknown>;

export const useUpdateTemplateText = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTemplateText>>,
    TError,
    { sectionId: string; textId: string; data: BodyType<UpdateTextDto> },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateTemplateText>>,
  TError,
  { sectionId: string; textId: string; data: BodyType<UpdateTextDto> },
  TContext
> => {
  const mutationOptions = getUpdateTemplateTextMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getImage = (
  sectionId: string,
  imageId: string,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<string>(
    { url: `/intranet-api/templates/section/${sectionId}/image/${imageId}`, method: 'GET', signal },
    options,
  );
};

export const getGetImageQueryKey = (sectionId: string, imageId: string) => {
  return [`/intranet-api/templates/section/${sectionId}/image/${imageId}`] as const;
};

export const getGetImageQueryOptions = <TData = Awaited<ReturnType<typeof getImage>>, TError = ErrorType<unknown>>(
  sectionId: string,
  imageId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getImage>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetImageQueryKey(sectionId, imageId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getImage>>> = ({ signal }) =>
    getImage(sectionId, imageId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(sectionId && imageId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getImage>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetImageQueryResult = NonNullable<Awaited<ReturnType<typeof getImage>>>;
export type GetImageQueryError = ErrorType<unknown>;

export function useGetImage<TData = Awaited<ReturnType<typeof getImage>>, TError = ErrorType<unknown>>(
  sectionId: string,
  imageId: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getImage>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getImage>>, TError, TData>, 'initialData'>;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetImage<TData = Awaited<ReturnType<typeof getImage>>, TError = ErrorType<unknown>>(
  sectionId: string,
  imageId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getImage>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getImage>>, TError, TData>, 'initialData'>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetImage<TData = Awaited<ReturnType<typeof getImage>>, TError = ErrorType<unknown>>(
  sectionId: string,
  imageId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getImage>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetImage<TData = Awaited<ReturnType<typeof getImage>>, TError = ErrorType<unknown>>(
  sectionId: string,
  imageId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getImage>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetImageQueryOptions(sectionId, imageId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const updateTemplateImage = (
  sectionId: string,
  imageId: string,
  updateTemplateImageBody: BodyType<UpdateTemplateImageBody>,
  options?: SecondParameter<typeof request>,
) => {
  const formData = new FormData();
  formData.append('file', updateTemplateImageBody.file);

  return request<void>(
    {
      url: `/intranet-api/templates/section/${sectionId}/image/${imageId}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  );
};

export const getUpdateTemplateImageMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTemplateImage>>,
    TError,
    { sectionId: string; imageId: string; data: BodyType<UpdateTemplateImageBody> },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTemplateImage>>,
  TError,
  { sectionId: string; imageId: string; data: BodyType<UpdateTemplateImageBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTemplateImage>>,
    { sectionId: string; imageId: string; data: BodyType<UpdateTemplateImageBody> }
  > = (props) => {
    const { sectionId, imageId, data } = props ?? {};

    return updateTemplateImage(sectionId, imageId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateTemplateImageMutationResult = NonNullable<Awaited<ReturnType<typeof updateTemplateImage>>>;
export type UpdateTemplateImageMutationBody = BodyType<UpdateTemplateImageBody>;
export type UpdateTemplateImageMutationError = ErrorType<unknown>;

export const useUpdateTemplateImage = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTemplateImage>>,
    TError,
    { sectionId: string; imageId: string; data: BodyType<UpdateTemplateImageBody> },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateTemplateImage>>,
  TError,
  { sectionId: string; imageId: string; data: BodyType<UpdateTemplateImageBody> },
  TContext
> => {
  const mutationOptions = getUpdateTemplateImageMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getTemplates = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<Section[]>({ url: `/intranet-api/templates`, method: 'GET', signal }, options);
};

export const getGetTemplatesQueryKey = () => {
  return [`/intranet-api/templates`] as const;
};

export const getGetTemplatesQueryOptions = <
  TData = Awaited<ReturnType<typeof getTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTemplates>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTemplatesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTemplates>>> = ({ signal }) =>
    getTemplates(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTemplates>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof getTemplates>>>;
export type GetTemplatesQueryError = ErrorType<unknown>;

export function useGetTemplates<
  TData = Awaited<ReturnType<typeof getTemplates>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTemplates>>, TError, TData>> &
    Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getTemplates>>, TError, TData>, 'initialData'>;
  request?: SecondParameter<typeof request>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetTemplates<
  TData = Awaited<ReturnType<typeof getTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTemplates>>, TError, TData>> &
    Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getTemplates>>, TError, TData>, 'initialData'>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetTemplates<
  TData = Awaited<ReturnType<typeof getTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTemplates>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetTemplates<
  TData = Awaited<ReturnType<typeof getTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTemplates>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetTemplatesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getEmailTemplateForState = (
  state:
    | 'INITIAL'
    | 'MODEL_CORRECT_DOCTOR_CORRECT'
    | 'MODEL_CORRECT_DOCTOR_CORRECT_IN_FUTURE'
    | 'MODEL_CORRECT_DOCTOR_CHANGE_NEEDED'
    | 'MODEL_WRONG'
    | 'UNCLEAR',
  params: GetEmailTemplateForStateParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<EmailTemplateDto>(
    { url: `/intranet-api/templates/email/${state}`, method: 'GET', params, signal },
    options,
  );
};

export const getGetEmailTemplateForStateQueryKey = (
  state:
    | 'INITIAL'
    | 'MODEL_CORRECT_DOCTOR_CORRECT'
    | 'MODEL_CORRECT_DOCTOR_CORRECT_IN_FUTURE'
    | 'MODEL_CORRECT_DOCTOR_CHANGE_NEEDED'
    | 'MODEL_WRONG'
    | 'UNCLEAR',
  params: GetEmailTemplateForStateParams,
) => {
  return [`/intranet-api/templates/email/${state}`, ...(params ? [params] : [])] as const;
};

export const getGetEmailTemplateForStateQueryOptions = <
  TData = Awaited<ReturnType<typeof getEmailTemplateForState>>,
  TError = ErrorType<unknown>,
>(
  state:
    | 'INITIAL'
    | 'MODEL_CORRECT_DOCTOR_CORRECT'
    | 'MODEL_CORRECT_DOCTOR_CORRECT_IN_FUTURE'
    | 'MODEL_CORRECT_DOCTOR_CHANGE_NEEDED'
    | 'MODEL_WRONG'
    | 'UNCLEAR',
  params: GetEmailTemplateForStateParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmailTemplateForState>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEmailTemplateForStateQueryKey(state, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEmailTemplateForState>>> = ({ signal }) =>
    getEmailTemplateForState(state, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!state, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getEmailTemplateForState>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetEmailTemplateForStateQueryResult = NonNullable<Awaited<ReturnType<typeof getEmailTemplateForState>>>;
export type GetEmailTemplateForStateQueryError = ErrorType<unknown>;

export function useGetEmailTemplateForState<
  TData = Awaited<ReturnType<typeof getEmailTemplateForState>>,
  TError = ErrorType<unknown>,
>(
  state:
    | 'INITIAL'
    | 'MODEL_CORRECT_DOCTOR_CORRECT'
    | 'MODEL_CORRECT_DOCTOR_CORRECT_IN_FUTURE'
    | 'MODEL_CORRECT_DOCTOR_CHANGE_NEEDED'
    | 'MODEL_WRONG'
    | 'UNCLEAR',
  params: GetEmailTemplateForStateParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmailTemplateForState>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getEmailTemplateForState>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetEmailTemplateForState<
  TData = Awaited<ReturnType<typeof getEmailTemplateForState>>,
  TError = ErrorType<unknown>,
>(
  state:
    | 'INITIAL'
    | 'MODEL_CORRECT_DOCTOR_CORRECT'
    | 'MODEL_CORRECT_DOCTOR_CORRECT_IN_FUTURE'
    | 'MODEL_CORRECT_DOCTOR_CHANGE_NEEDED'
    | 'MODEL_WRONG'
    | 'UNCLEAR',
  params: GetEmailTemplateForStateParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmailTemplateForState>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getEmailTemplateForState>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetEmailTemplateForState<
  TData = Awaited<ReturnType<typeof getEmailTemplateForState>>,
  TError = ErrorType<unknown>,
>(
  state:
    | 'INITIAL'
    | 'MODEL_CORRECT_DOCTOR_CORRECT'
    | 'MODEL_CORRECT_DOCTOR_CORRECT_IN_FUTURE'
    | 'MODEL_CORRECT_DOCTOR_CHANGE_NEEDED'
    | 'MODEL_WRONG'
    | 'UNCLEAR',
  params: GetEmailTemplateForStateParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmailTemplateForState>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetEmailTemplateForState<
  TData = Awaited<ReturnType<typeof getEmailTemplateForState>>,
  TError = ErrorType<unknown>,
>(
  state:
    | 'INITIAL'
    | 'MODEL_CORRECT_DOCTOR_CORRECT'
    | 'MODEL_CORRECT_DOCTOR_CORRECT_IN_FUTURE'
    | 'MODEL_CORRECT_DOCTOR_CHANGE_NEEDED'
    | 'MODEL_WRONG'
    | 'UNCLEAR',
  params: GetEmailTemplateForStateParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmailTemplateForState>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetEmailTemplateForStateQueryOptions(state, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}
