/**
 * HAM API – Generated by orval 🍺
 * Do not edit manually.
 */
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { ErrorType } from '../../axios';
import { request } from '../../axios';
import type {
  ListPatientRegistrationDetailsForPracticeParams,
  PracticePageDto,
  SetAdmittedStateForRegistrationParams,
} from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const setAdmittedStateForRegistration = (
  registrationId: string,
  params: SetAdmittedStateForRegistrationParams,
  options?: SecondParameter<typeof request>,
) => {
  return request<void>(
    { url: `/intranet-api/practice-registrations/${registrationId}`, method: 'PUT', params },
    options,
  );
};

export const getSetAdmittedStateForRegistrationMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setAdmittedStateForRegistration>>,
    TError,
    { registrationId: string; params: SetAdmittedStateForRegistrationParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setAdmittedStateForRegistration>>,
  TError,
  { registrationId: string; params: SetAdmittedStateForRegistrationParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setAdmittedStateForRegistration>>,
    { registrationId: string; params: SetAdmittedStateForRegistrationParams }
  > = (props) => {
    const { registrationId, params } = props ?? {};

    return setAdmittedStateForRegistration(registrationId, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetAdmittedStateForRegistrationMutationResult = NonNullable<
  Awaited<ReturnType<typeof setAdmittedStateForRegistration>>
>;

export type SetAdmittedStateForRegistrationMutationError = ErrorType<unknown>;

export const useSetAdmittedStateForRegistration = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setAdmittedStateForRegistration>>,
    TError,
    { registrationId: string; params: SetAdmittedStateForRegistrationParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setAdmittedStateForRegistration>>,
  TError,
  { registrationId: string; params: SetAdmittedStateForRegistrationParams },
  TContext
> => {
  const mutationOptions = getSetAdmittedStateForRegistrationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const listPatientRegistrationDetailsForPractice = (
  params: ListPatientRegistrationDetailsForPracticeParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<PracticePageDto>(
    { url: `/intranet-api/practice-registrations`, method: 'GET', params, signal },
    options,
  );
};

export const getListPatientRegistrationDetailsForPracticeQueryKey = (
  params: ListPatientRegistrationDetailsForPracticeParams,
) => {
  return [`/intranet-api/practice-registrations`, ...(params ? [params] : [])] as const;
};

export const getListPatientRegistrationDetailsForPracticeQueryOptions = <
  TData = Awaited<ReturnType<typeof listPatientRegistrationDetailsForPractice>>,
  TError = ErrorType<unknown>,
>(
  params: ListPatientRegistrationDetailsForPracticeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof listPatientRegistrationDetailsForPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListPatientRegistrationDetailsForPracticeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listPatientRegistrationDetailsForPractice>>> = ({ signal }) =>
    listPatientRegistrationDetailsForPractice(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listPatientRegistrationDetailsForPractice>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ListPatientRegistrationDetailsForPracticeQueryResult = NonNullable<
  Awaited<ReturnType<typeof listPatientRegistrationDetailsForPractice>>
>;
export type ListPatientRegistrationDetailsForPracticeQueryError = ErrorType<unknown>;

export function useListPatientRegistrationDetailsForPractice<
  TData = Awaited<ReturnType<typeof listPatientRegistrationDetailsForPractice>>,
  TError = ErrorType<unknown>,
>(
  params: ListPatientRegistrationDetailsForPracticeParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof listPatientRegistrationDetailsForPractice>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof listPatientRegistrationDetailsForPractice>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useListPatientRegistrationDetailsForPractice<
  TData = Awaited<ReturnType<typeof listPatientRegistrationDetailsForPractice>>,
  TError = ErrorType<unknown>,
>(
  params: ListPatientRegistrationDetailsForPracticeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof listPatientRegistrationDetailsForPractice>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof listPatientRegistrationDetailsForPractice>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useListPatientRegistrationDetailsForPractice<
  TData = Awaited<ReturnType<typeof listPatientRegistrationDetailsForPractice>>,
  TError = ErrorType<unknown>,
>(
  params: ListPatientRegistrationDetailsForPracticeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof listPatientRegistrationDetailsForPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useListPatientRegistrationDetailsForPractice<
  TData = Awaited<ReturnType<typeof listPatientRegistrationDetailsForPractice>>,
  TError = ErrorType<unknown>,
>(
  params: ListPatientRegistrationDetailsForPracticeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof listPatientRegistrationDetailsForPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getListPatientRegistrationDetailsForPracticeQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPracticeTableIntro = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<string>({ url: `/intranet-api/practice-registrations/templates`, method: 'GET', signal }, options);
};

export const getGetPracticeTableIntroQueryKey = () => {
  return [`/intranet-api/practice-registrations/templates`] as const;
};

export const getGetPracticeTableIntroQueryOptions = <
  TData = Awaited<ReturnType<typeof getPracticeTableIntro>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPracticeTableIntro>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPracticeTableIntroQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPracticeTableIntro>>> = ({ signal }) =>
    getPracticeTableIntro(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPracticeTableIntro>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPracticeTableIntroQueryResult = NonNullable<Awaited<ReturnType<typeof getPracticeTableIntro>>>;
export type GetPracticeTableIntroQueryError = ErrorType<unknown>;

export function useGetPracticeTableIntro<
  TData = Awaited<ReturnType<typeof getPracticeTableIntro>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPracticeTableIntro>>, TError, TData>> &
    Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getPracticeTableIntro>>, TError, TData>, 'initialData'>;
  request?: SecondParameter<typeof request>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPracticeTableIntro<
  TData = Awaited<ReturnType<typeof getPracticeTableIntro>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPracticeTableIntro>>, TError, TData>> &
    Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getPracticeTableIntro>>, TError, TData>, 'initialData'>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPracticeTableIntro<
  TData = Awaited<ReturnType<typeof getPracticeTableIntro>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPracticeTableIntro>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetPracticeTableIntro<
  TData = Awaited<ReturnType<typeof getPracticeTableIntro>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPracticeTableIntro>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPracticeTableIntroQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}
